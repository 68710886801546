import React, { useEffect, useState } from 'react';
import '../../CSS file/applicationcontent.css';
import { universitiesDetails, universitiesShortListedWithPagination } from '../../../Api/Api';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { getShortlistedUniversity } from '../../../StateManagement/slicers/universitySlicer';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { AiOutlineStar } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { BiLoader } from 'react-icons/bi';
import PaginationTab from '../../../Components/Pagination tab/PaginationTab';
import { formatURLName } from '../../../utils/helpers';
const clg_img = require('../../../assets/clg_profile_pic.png');
const no_data = require('../../../assets/no_data.svg');

function UniversitiesShortlist(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [shortListedLists, setShortListedLists] = useState([]);
  const [message, setMessage] = useState('');
  const [totalPage, setTotalPage] = useState('');

  // useEffect(() => {
  //   dispatch(getShortlistedUniversity());
  // }, [getShortlistedUniversity]);

  const shortlistedUniversities = useSelector(state => state.university.shortlistedUniversities);

  useEffect(() => {
    if (shortlistedUniversities.messages === 'No Shortlisted Universities Found') {
      setMessage('No Universities Found');
      setShortListedLists([]);
    } else {
      if (shortlistedUniversities?.data?.universities) {
        let count1 = shortlistedUniversities?.data?.count / 10;
        let value = Math.ceil(count1);
        setTotalPage(value);
        setShortListedLists(shortlistedUniversities?.data?.universities);
      }
    }
  }, [shortlistedUniversities]);

  async function paginate(number) {
    let data = number * 10 + 1 - 10;
    let res = await universitiesShortListedWithPagination({
      entries_from: data,
    });
    setShortListedLists(res.data.courses);
  }
  function handleViewDetails(e) {
    localStorage.setItem('university_id', e.id);
    navigate('/ViewDetailUnivercity');
  }

  const renderDataOfApplication = shortListedLists.map(perApkData => {
    return (
      <tr className="data_table_content" key={Math.random()}>
        <td className="data_content_container" onClick={() => handleViewDetails(perApkData)}>
          <div className="clg_img_container_circle">
            <img
              loading="lazy"
              src={perApkData?.university_cover_img ? perApkData?.university_cover_img : clg_img}
              alt="clg_pic"
              className="clg_img_self"
            />
          </div>
          <div className="univ_date_container">
            <p className="univ_title_text highlight_text_univ" title={perApkData?.u_name}>
              {perApkData?.u_name ? perApkData?.u_name.substring(0, 30) + ' ...' : '------'}
            </p>
            {/* <p className="date_text_">Starting :{perApkData.universityName} </p> */}
          </div>
        </td>
        <td>
          {' '}
          <p className="stage_text">
            {perApkData.university_locations[0]?.university_in_country[0]?.country_name
              ? perApkData.university_locations[0]?.university_in_country[0]?.country_name
              : '-----'}
          </p>
        </td>
        <td>
          <p className="stage_text" title={perApkData.university_locations[0]?.campus_name}>
            {perApkData.university_locations[0]?.campus_name
              ? perApkData.university_locations[0]?.campus_name
              : '-----'}
          </p>
        </td>
        <td>
          <p className="stage_text">{perApkData.global_rating}/5</p>
        </td>

        <td>
          <button className="view_btn_container" onClick={() => handleViewDetails(perApkData)}>
            Apply Now
          </button>
        </td>
      </tr>
    );
  });

  function handleViewDetails(e) {
    localStorage.setItem('university_id', e.id);
    const formattedName = formatURLName(e.u_name);

    navigate(`/UnivercityMain/ViewDetailUnivercity/${formattedName}`);
  }
  return (
    <div className="application_content_box_container">
      <div className="application_main_content_table">
        {shortListedLists.length > 0 ? (
          <table className="heading_container">
            <tr className="data_table_content">
              <th>University</th>
              <th>Country</th>
              <th>Campus name</th>
              <th>Global Rating</th>
              <th>Action</th>
            </tr>

            {renderDataOfApplication}
          </table>
        ) : (
          <div className="no_likes_container center_no_likes_container">
            <img src={no_data.default} alt="no_data" loading="lazy" />
            <Link to="/university">Go to the shortlisted section if you do not have shortlisted</Link>
          </div>
        )}
      </div>
      <div className="pagination_tab_univercity_course_container">
        {shortListedLists.length > 0 ? (
          <PaginationTab postsPerPage={totalPage} totalPosts={shortListedLists.length} paginate={paginate} />
        ) : null}
      </div>
    </div>
  );
}

export default UniversitiesShortlist;
