import React, { useState, useEffect } from 'react';
import EditProfileHeader from './EditProfileHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getShortlistedUniversity } from '../../StateManagement/slicers/universitySlicer';
import { fetchSpecializationsById, fetchUniversityIntakesById, fetchIntakeYearsById } from '../../Api/Api';
import HeaderSubtitle from './HeaderSubtitle';
import '../CSS file/righteditprofile.css';

export default function Content3() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);

  const { desired_study_level, desired_intake, desired_specialization, desired_year_of_admission } =
    user.user_interests_and_background || {};

  const [desiredCountries, setDesiredCountries] = useState('No Desired Countries Selected');
  const shortlistedUniversities = useSelector(state => state.university.shortlistedUniversities);

  const [studyLevel, setStudyLevel] = useState([]);

  const [desiredSpecialization, setDesiredSpecialization] = useState('');
  const [desiredIntake, setDesiredIntake] = useState([]);
  const [desiredYear, setDesiredYear] = useState('');
  const [shortedListed, setShortedListed] = useState([]);
  // useEffect(() => {
  //   dispatch(getShortlistedUniversity());
  // }, []);
  useEffect(() => {
    if (shortlistedUniversities) {
      if (shortlistedUniversities?.data?.filtered_universities) {
        setShortedListed(shortlistedUniversities?.data?.filtered_universities);
      }
    }
  }, [shortlistedUniversities?.filtered_universities]);

  useEffect(() => {
    if (user) {
      if (user?.desired_study_level) {
        let arr = [];
        user?.desired_study_level.forEach(item => {
          arr.push(item.program_level_name + ' ' + ',');
        });

        setStudyLevel(JSON.stringify(arr));
      }
    }
    if (user) {
      if (user?.desired_intake) {
        let arr = [];
        let size = user?.desired_intake.length;
        user?.desired_intake.forEach((item, index) => {
          if (index + 1 < size) {
            arr.push(item.intake_name + ' ' + ',');
          } else {
            arr.push(item.intake_name);
          }
        });
        setDesiredIntake(JSON.stringify(arr));
      }
    }
  }, [user]);

  useEffect(() => {
    let countries = '';
    if (user?.preferred_countries) {
      if (user?.preferred_countries.length > 0) {
        user.preferred_countries.forEach(country => {
          if (country !== null && country !== '') {
            if (countries !== '') {
              countries = countries + ',' + country.country_name;
            } else {
              countries = country.country_name;
            }
          }
        });
        setDesiredCountries(countries);
      }
    }
    async function checkSpecialization() {
      if (user?.user_interests_and_background?.desired_specialization) {
        let value = user?.user_interests_and_background?.desired_specialization;

        setDesiredSpecialization(value);
      }
    }

    async function checkYear() {
      if (user?.desired_year_of_admissions) {
        let arr = [];
        let value = user?.desired_year_of_admissions;

        value.forEach(item => {
          arr.push(item.year_numeric + ' ' + ',');
        });

        setDesiredYear(arr);
      }
    }

    checkSpecialization();
    //checkIntake();
    checkYear();
  }, [user]);

  const allStudyData = [
    {
      aspiratantTitle: 'Study Level',
      aspiratantSubtitle: desired_study_level || '-----',
    },
    {
      aspiratantTitle: 'Year Of Admission',
      aspiratantSubtitle: desired_year_of_admission || '-----',
    },
    {
      aspiratantTitle: 'Desired Intake',
      aspiratantSubtitle: desired_intake || '-----',
    },
    {
      aspiratantTitle: 'Desired Specialization',
      aspiratantSubtitle: desired_specialization || '-----',
    },
  ];
  const allShortlist = [
    {
      shortlistTitle: 'Institure name 1',
      shortlistSubtitle: user?.user_interests_and_background?.institute_name
        ? user?.user_interests_and_background?.institute_name
        : '-----',
    },
    {
      shortlistTitle: 'Area',
      shortlistSubtitle: user?.user_interests_and_background?.desired_year_of_admission
        ? user?.user_interests_and_background?.desired_year_of_admission
        : '-----',
    },
  ];

  const renderShortlistedUnivercities = shortedListed?.slice(0, 2).map(perUniver => {
    return (
      <div className="stydy_wrap_container">
        <div className="institute_name_container">
          <h6 className="date_of_title_text">Institute Name</h6>
          <h6 className="sub_text_date">{perUniver.u_name ? perUniver.u_name : '------'}</h6>
        </div>
        <div className="institute_name_container">
          <h6 className="date_of_title_text">Area</h6>
          <h6 className="sub_text_date">
            {perUniver.university_locations[0]?.campus_name ? perUniver.university_locations[0]?.campus_name : '------'}
          </h6>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="profile_eligibility_under_main_container">
        <div className="left_container">
          <div className="study_aspirint_under_container">
            <EditProfileHeader editHeaderName="Study Aspirations" edit="aspirationInfo" dlt="dlt" />
            <div className="stydy_wrap_container">
              {allStudyData.map(perStudy => {
                return (
                  <div className="aspiriant_container" key={Math.random()}>
                    <HeaderSubtitle
                      key={Math.random()}
                      titleHeader={perStudy.aspiratantTitle}
                      subTitleHeader={perStudy.aspiratantSubtitle}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="study_aspirint_under_container">
            <EditProfileHeader editHeaderName="Shortlisted Univercities" edit="univercitiInfo" dlt="dlt" />
            <div className="">
              <h6 className="date_of_title_text">Have you shortlisted any Universities yet </h6>
              <h6 className="sub_text_date">{shortedListed.length > 0 ? 'Yes' : 'No'}</h6>
            </div>
            {shortedListed.length > 0 ? renderShortlistedUnivercities : null}
          </div>
        </div>

        <div className="right_container">
          <div className="desired_destination_contianer">
            <EditProfileHeader editHeaderName="Desired Destination" edit="destinationInfo" dlt="dlt" />
            <HeaderSubtitle
              titleHeader="Country"
              subTitleHeader={
                user?.user_interests_and_background?.preferred_country
                  ? JSON.parse(user?.user_interests_and_background?.preferred_country)
                  : '------'
              }
            />
          </div>

          <div className="desired_destination_contianer">
            <EditProfileHeader editHeaderName="Study Budget" edit="budgetInfo" dlt="dlt" />
            <HeaderSubtitle
              titleHeader="Amount"
              subTitleHeader={
                user?.user_interests_and_background?.budget ? user?.user_interests_and_background?.budget : '------'
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
