import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSearchUniversal, selectUserFullNameParams } from '../../StateManagement/slicers/userSlicer';
import logo_ds from '../../assets/ds_logo.png';
import './commonheader.css';
import { MdClose, MdOutlineHistory } from 'react-icons/md';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import Modal from 'react-modal';
import './profilemenu.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import Notification from './Notification';
import ProfileMenu from './ProfileMenu';
import './expansionsearch.css';
import { storeUniversityTypeToRender } from '../../StateManagement/slicers/universitySlicer';
import { removeStoreDataOfUniversity } from '../../StateManagement/slicers/removeStoreData';

const customStyles = {
  content: {
    top: '5%',
    bottom: 'auto',
  },
};

const allSuggestion = [
  {
    suggestionItem: 'university',
  },
  {
    suggestionItem: 'university',
  },
  {
    suggestionItem: 'university',
  },
];

export default function CommonHeader() {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalSearch, setModalSearch] = React.useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [editSearchItem, setEditSearchItem] = useState([]);
  const fullNameParams = useSelector(selectUserFullNameParams);

  function closeSearchModal() {
    setModalSearch(false);
  }

  function handleSearchclick() {
    dispatch(fetchSearchUniversal({ search_text: searchInput }));
    navigate('/SearchMain');
    setModalSearch(false);
  }
  function openSearchUpload() {
    setModalSearch(true);
  }
  function handleSearch() {
    if (!searchInput) {
    } else {
      setEditSearchItem([...editSearchItem, searchInput]);
      setSearchInput('');
    }
  }

  function handleClearAll() {}

  function handleSearchInput(e) {
    setSearchInput(e.target.value);
  }
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      setSearchInput('');
      dispatch(fetchSearchUniversal({ search_text: searchInput }));
      navigate('/SearchMain');
      setModalSearch(false);
    }
  };

  const renderSuggestionItems = allSuggestion.map(perSuggest => {
    return (
      <li className="result_li_content" key={Math.random()}>
        <a className="result_one_text" href="sugestion">
          {perSuggest.suggestionItem}
        </a>
      </li>
    );
  });

  const renderHistory = editSearchItem.map(perHistory => {
    return (
      <button className="btn_search_icon_text_btn" key={Math.random()}>
        <MdOutlineHistory className="search_result_icon" /> <span className="text_search">{perHistory}</span>
        <button className="cancel_history_item">
          <MdClose className="cancel_history_icon" />
        </button>
      </button>
    );
  });

  return (
    <>
      <nav className="navbar navbar-expand-sm border-bottom navbar-light">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <a className="navbar-brand" href="#viewprofile">
            <img src={logo_ds} alt="DWEC_logo" loading="lazy" className="img-responsive img-fluid logo_dwec_img1" />
          </a>

          <div className="collapse navbar-collapse justify-content-center" id="collapsibleNavbar">
            <div className="all_navlinks_title">
              <NavLink exact className="navs_text" to="/application-stepper">
                Dashboard
              </NavLink>
              <NavLink exact className="navs_text" to={`/feeds`}>
                Home
              </NavLink>

              <NavLink
                exact
                className="navs_text"
                to="/university"
                onClick={() => {
                  removeStoreDataOfUniversity(dispatch);
                  dispatch(
                    storeUniversityTypeToRender({
                      type: 'Universities',
                      tab: 1,
                    }),
                  );
                }}
              >
                University
              </NavLink>
              <NavLink
                exact
                className="navs_text"
                to="/connection"
                onClick={() => {
                  removeStoreDataOfUniversity(dispatch);
                  dispatch(
                    storeUniversityTypeToRender({
                      type: 'Connection',
                      tab: 1,
                    }),
                  );
                }}
              >
                Connection
              </NavLink>

              <NavLink exact className="navs_text" to="/my-activity">
                My Activity
              </NavLink>
            </div>
          </div>

          <div className="search_profile_notification_container">
            <button className="search_btn" onClick={openSearchUpload}>
              <BsSearch className="icon_search_self" />
            </button>

            <Modal
              isOpen={modalSearch}
              onRequestClose={closeSearchModal}
              style={customStyles}
              closeTimeoutMS={500}
              ariaHideApp={false}
              contentLabel="Example Modal"
            >
              <div className="modal_search_container">
                <div className="modal_under_search_close_btn_contianer">
                  <h6 className="search_heading">Search</h6>

                  <button className="close_btn_contianer" onClick={closeSearchModal}>
                    <MdClose className="close_self" />
                  </button>
                </div>
                <div className="search_input_clear_container">
                  <div
                    style={{
                      width: '100%',
                      position: 'relative',
                      display: 'flex',
                    }}
                  >
                    <input
                      type="text"
                      className="search_input"
                      placeholder="Search here ..."
                      value={searchInput}
                      onKeyDown={handleKeyDown}
                      onChange={e => handleSearchInput(e)}
                    />
                  </div>
                  <button className="search_btn_self" onClick={handleSearchclick}>
                    Search
                  </button>
                </div>

                <div className="recent_search_container">
                  <h6 className="title_text_recent">search suggestions</h6>

                  <ul className="recent_search_result_container">{renderSuggestionItems}</ul>
                </div>

                <hr className="search_modal_line" />
                <div className="history_clear_all_btn_container">
                  <h6 className="title_text_recent">History</h6>

                  <a className="title_text_recent" href="#clear" onClick={handleClearAll}>
                    clear all
                  </a>
                </div>

                {renderHistory}
              </div>
            </Modal>

            <Notification />

            {/* <button className="search_btn">
        <img src={pro_pic} className="profile_pic" alt="profile" />
      </button> */}

            <ProfileMenu />
          </div>
        </div>
      </nav>
    </>
  );
}
