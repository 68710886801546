import React, { useState } from 'react';
import { MdOutlineModeEdit, MdOutlinePersonOutline, MdLogin, MdOutlineHelpOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import pro_pic from '../../assets/profile_pic.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuItem, MenuDivider } from '@szhsin/react-menu';
import './profilemenu.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { logoutUser } from '../../StateManagement/slicers/authSlicer';

export default function ProfileMenu() {
  const user = useSelector(state => state.user.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleProfileItem() {}

  function handleEdit() {
    navigate('/Editprofile');
  }
  function handleView() {
    navigate('/my-activity');
  }

  function handleDeactivate() {
    navigate('/');
    dispatch(logoutUser());
  }

  return (
    <Menu
      menuButton={
        <button className="search_btn1">
          <img
            src={user?.user_personal_profile?.profile_pic ? user?.user_personal_profile?.profile_pic : pro_pic}
            className="pic round_circle"
            alt="profile"
            loading="lazy"
          />
        </button>
      }
    >
      <div className="profile_show_container">
        <img
          src={user?.user_personal_profile?.profile_pic ? user?.user_personal_profile?.profile_pic : pro_pic}
          alt="img"
          loading="lazy"
          className="profile_pic_menu"
        />
        <h6 className="title_profile_menu_text">
          {user?.user_personal_profile?.first_name ? user?.user_personal_profile?.first_name : '------'}{' '}
          {user?.user_personal_profile?.last_name ? user?.user_personal_profile?.last_name : '------'}
        </h6>
        <p className="sub_profile_menu_text">Katherilon Pechoie</p>
      </div>

      <MenuItem onClick={handleEdit}>
        <div className="edit_profile_contianer">
          <MdOutlineModeEdit className="edit_profile_icon" />
          <h6 className="profile_menu_text_one">Edit Profile</h6>
        </div>
      </MenuItem>

      <MenuItem onClick={handleView}>
        <div className="edit_profile_contianer">
          <MdOutlinePersonOutline className="edit_profile_icon" />
          <h6 className="profile_menu_text_one">View Profile</h6>
        </div>
      </MenuItem>
      <MenuDivider />

      <MenuItem onClick={handleProfileItem}>
        <div className="edit_profile_contianer">
          <MdOutlineHelpOutline className="edit_profile_icon" />
          <h6 className="profile_menu_text_one">Grievance Center</h6>
        </div>
      </MenuItem>

      <MenuItem onClick={handleDeactivate}>
        <div className="edit_profile_contianer">
          <MdLogin className="edit_profile_icon" />
          <h6 className="profile_menu_text_one">Log Out</h6>
        </div>
      </MenuItem>
    </Menu>
  );
}
