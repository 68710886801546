import React, { useState, useEffect } from 'react';
import '../../CSS file/contentcourse.css';
import { MdBlock, MdCheckCircle } from 'react-icons/md';

import { useDispatch, useSelector } from 'react-redux';
import {
  getShortlistedCourse,
  getFeaturedCourse,
  getPopularCourse,
  getRecommendedCourse,
  getTopCourse,
} from '../../../StateManagement/slicers/courseSlicer';
import { useNavigate } from 'react-router-dom';
import { addOrRemoveUserPreferenceOnCourses } from '../../../StateManagement/slicers/userSlicer';
import { universitiesCoursesWithPagination, courseShortListedWithPagination } from '../../../Api/Api';
import PaginationTab from '../../../Components/Pagination tab/PaginationTab';
import CustDialogBox from '../../../Components/CustDialogBox';
import ApplicationDialogContent from '../ApplicationDialogContent';
import CourseSkeletonLoader from '../../../Components/skeleton loader/CourseSkeletonLoader';
import CustLoader from '../../../Components/Loader/CustLoader';
import { ReactComponent as ShortListIcon } from '../../../assets/shortlistIcon.svg';
const no_shortlisted_course = require('../../../assets/no_shortlisted_course.png');

function ContentCourse() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getShortlistedCourse());
    dispatch(getTopCourse());
    dispatch(getRecommendedCourse());
    dispatch(getPopularCourse());
    dispatch(getFeaturedCourse());
  }, [dispatch]);

  const [courseContent, setCourseContent] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [totalPage, setTotalPage] = useState('');
  const [message, setMessage] = useState('');
  const { isLoading } = useSelector(state => state.common);

  const course = useSelector(state => state.university.universitiesCourses);
  const { shortlistedCourses, courseTypeToRender, popularCourses, recommendedCourses, featuredCourses } = useSelector(
    state => state.course,
  );
  const searchCourses = useSelector(state => state.user.searchCourses);
  const isSearchCourse = useSelector(state => state.university.isSearchCourse);
  const universityTypeToRender = useSelector(state => state.university.universityTypeToRender);
  const [toggleModal, setToggleModal] = useState(false);
  useEffect(() => {
    if (!isSearchCourse) {
      if (universityTypeToRender === 'Courses') {
        let count = course.total_courses / 50;
        let value = Math.ceil(count);
        setTotalPage(value);
        setCourseContent(course?.courses);
      } else if (universityTypeToRender === 'Popular Courses') {
        setCourseContent(popularCourses);
      } else if (universityTypeToRender === 'Recommended Courses') {
        setCourseContent(recommendedCourses);
      } else if (universityTypeToRender === 'Featured Courses') {
        setCourseContent(featuredCourses);
      } else if (universityTypeToRender === 'Shortlisted Courses') {
        if (shortlistedCourses.messages === 'No Shortlisted Courses Found') {
          setMessage('No Courses Found');
          setCourseContent([]);
        } else {
          if (shortlistedCourses?.data?.courses) {
            let count = shortlistedCourses.total_count / 10;
            let value = Math.ceil(count);
            setTotalPage(value);

            setCourseContent(shortlistedCourses?.data?.courses);
          }
        }
      }
    } else {
      if (searchCourses.search_results) {
        setCourseContent(searchCourses.search_results);
      }
    }
  }, [
    course,
    shortlistedCourses,
    popularCourses,
    recommendedCourses,
    featuredCourses,
    courseTypeToRender,
    universityTypeToRender,
    searchCourses,
  ]);

  useEffect(() => {
    if (shortlistedCourses.length > 0) {
      let shortlistedIds = [];
      shortlistedCourses.forEach(university => {
        shortlistedIds.push(university.id);
      });
      setSelectedValue(shortlistedIds);
    }
  }, [shortlistedCourses]);

  const handleApply = () => {
    setToggleModal(true);
  };

  function handleCourseSelect(e) {
    const courseId = e?.id;
    const courseName = e.course_has_entries_in_course.name;
    const path = `/UnivercityMain/ViewDetailCourses/${courseId}/${courseName}`;
    navigate(path);
  }

  function shortlistCourse(course) {
    if (universityTypeToRender === 'Shortlisted Courses') {
      dispatch(
        addOrRemoveUserPreferenceOnCourses({
          if_shortlisted: false,
          course_id: course.perCourseContent.course_id,
          university_id: course.perCourseContent.university_id,
        }),
      );
    }
    if (universityTypeToRender === 'Courses') {
      dispatch(
        addOrRemoveUserPreferenceOnCourses({
          if_shortlisted: !course.perCourseContent.if_shortlisted,
          course_id: course.perCourseContent.course_id,
          university_id: course.perCourseContent.university_id,
        }),
      );
    }
    // addOrRemoveUserPreferenceOnCourses({
    //   if_shortlisted: true,
    //   course_id: course.perCourseContent.course_id,
    //   university_id: course.perCourseContent.university_id,
    // })
  }

  async function paginate(number) {
    if (universityTypeToRender === 'Courses') {
      let data = (number - 1) * 10 + 1;
      let res = await universitiesCoursesWithPagination({
        entries_from: data,
      });

      setCourseContent(res.data.courses);
    }
    if (universityTypeToRender === 'Shortlisted Courses') {
      let data = (number - 1) * 10 + 1;
      let res = await courseShortListedWithPagination({
        entries_from: data,
      });

      setCourseContent(res.data.courses);
    }
  }

  const renderCourseContent =
    courseContent &&
    courseContent?.length > 0 &&
    courseContent?.map((perCourseContent, index) => {
      return (
        <div className="course_box_container" key={Math.random()}>
          <div className="course_box_under_container">
            <h5 className="course_text_title" title={perCourseContent?.course_has_entries_in_course?.name}>
              {perCourseContent?.course_has_entries_in_course?.name
                ? perCourseContent?.course_has_entries_in_course?.name.substring(0, 42) + '...'
                : '-----'}
            </h5>
            <p className="course_univer_txt">
              {perCourseContent?.course_given_by_university?.u_name
                ? perCourseContent?.course_given_by_university?.u_name
                : '----'}
            </p>
            {/* <p className="course_country_text">
            {perCourseContent?.universities_location[0]?.university_in_city[0]
              ?.city_name.length > 0
              ? perCourseContent?.universities_location[0]
                  ?.university_in_city[0]?.city_name
              : "----"}{" "}
            ,{" "}
            {perCourseContent?.universities_location[0]
              ?.university_in_country[0]?.country_name
              ? perCourseContent?.universities_location[0]
                  ?.university_in_country[0]?.country_name
              : "----"}
          </p> */}
          </div>

          <div className="course_like_btn">
            <div className="course_content_btn">
              <button
                type="button"
                className={
                  perCourseContent?.if_shortlisted === true || selectedValue.includes(perCourseContent.id)
                    ? 'like_icon_container red_color'
                    : 'like_icon_container'
                }
                onClick={() =>
                  shortlistCourse({
                    perCourseContent: perCourseContent,
                    index: index,
                  })
                }
              >
                {perCourseContent?.if_shortlisted ? (
                  <div className="icon_block_text_container">
                    <ShortListIcon className="blue_color" /> Shortlisted
                  </div>
                ) : (
                  <div className="icon_block_text_container">
                    <ShortListIcon className="red_color" /> Shortlist
                  </div>
                )}
              </button>
            </div>

            <button className="view_detail_btn_univercity" onClick={() => handleCourseSelect(perCourseContent)}>
              Details
            </button>
          </div>
          {perCourseContent?.if_shortlisted ? (
            <button type="button" className="apply_now_btn" onClick={handleApply}>
              Apply now
            </button>
          ) : null}
        </div>
      );
    });

  return (
    <>
      {isLoading ? <CustLoader /> : null}

      <CustDialogBox
        dialogHeaderName="Create Application Details"
        open={toggleModal}
        onClose={() => setToggleModal(false)}
      >
        <ApplicationDialogContent />
      </CustDialogBox>

      {courseContent && courseContent?.length > 0 ? (
        <>{renderCourseContent}</>
      ) : (
        // ) : message === "No Courses Found" ? (
        //   <div className="no_likes_container center_no_likes_container">

        //   </div>
        // <CourseSkeletonLoader />
        <img src={no_shortlisted_course} alt="dwec_like" loading="lazy" />
      )}
      {courseContent.length > 0 ? (
        <PaginationTab postsPerPage={totalPage} totalPosts={courseContent.length} paginate={paginate} />
      ) : null}
    </>
  );
}

export default ContentCourse;
