import React, { useEffect, useState } from 'react';
import profile_pic from '../../../assets/profile_pic.jpg';
import '../../CSS file/leftprofile.css';
// import ParagraphContainer from "./ReadMoreLess";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PropagateLoader from 'react-spinners/PropagateLoader';
import 'react-circular-progressbar/dist/styles.css';
import { useLocation } from 'react-router-dom';
import { MdCall, MdMailOutline } from 'react-icons/md';
import { BsHouseDoor, BsPhone } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoggedInUser } from '../../../StateManagement/slicers/userSlicer';
import { fetchApiBearerToken } from '../../../StateManagement/slicers/tokenSlicer';
import { getPreferredUniversity } from '../../../StateManagement/slicers/universitySlicer';
import LeftProfileSkeleton from '../../../Components/skeleton loader/LeftProfileSkeleton';
import LoadMoreText from '../../../utils/LoadMoreText';

export default function LeftProfile(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector(state => state.token.token);
  const { isLoading, preferredUniversities } = useSelector(state => state.university);
  const userFeedWall = useSelector(state => state.user.userWall);
  const userInfo = useSelector(state => (state.user.user ? state.user.user : state.auth.user));
  const { userConnectionList } = useSelector(state => state.user);
  const userBySearch = useSelector(state => state.user.storeUserBySearch);
  const [user, setUser] = useState([]);
  const [connectionList, setConnectionList] = useState([]);
  const [allScore, setAllScore] = useState([]);
  const [postArr, setPostArr] = useState([]);
  const [storePost, setStorePost] = useState('');

  useEffect(() => {
    if (userConnectionList) {
      setConnectionList(userConnectionList.data);
    }
  }, [userConnectionList]);

  useEffect(() => {
    setPostArr(userFeedWall);
  }, [userFeedWall]);

  useEffect(() => {
    if (location.state === 'id') {
      let result = postArr.filter(item => {
        return item.user_id === userBySearch.id;
      });

      if (result.length > 0) {
        setStorePost(result.length);
      }
    } else {
      let result = postArr.filter(item => {
        return item.user_id === userInfo.id;
      });
      setStorePost(result.length);
    }
  }, [postArr, location]);

  useEffect(() => {
    if (location.state === 'id') {
      setUser(userBySearch);
    } else {
      setUser(userInfo);
    }
  }, [userBySearch, userInfo]);

  const allCountItms = [
    {
      numberCount: storePost ? storePost : 0,
      textCount: 'Post',
    },
    {
      numberCount: connectionList?.followers?.length ? connectionList?.followers?.length : 0,
      textCount: 'Followers',
    },
    {
      numberCount: connectionList?.followings?.length ? connectionList?.followings?.length : 0,
      textCount: 'Following',
    },
  ];

  const [allUnivereName, setAllUnivereName] = useState([]);

  function handleEditProfile() {
    navigate('/EditProfile');
  }

  useEffect(() => {
    dispatch(fetchApiBearerToken());
  }, []);

  useEffect(() => {
    if (token != '' && token != undefined && token != null) {
      dispatch(fetchLoggedInUser());
    }
  }, [token]);

  useEffect(() => {
    if (user?.user_appeared_for_test) {
      if (user?.user_appeared_for_test.length > 0) {
        setAllScore(user.user_appeared_for_test);
      }
    }
  }, [user]);

  useEffect(() => {
    if (preferredUniversities.length > 0) {
      setAllUnivereName(preferredUniversities);
    }
  }, [preferredUniversities]);

  useEffect(() => {
    if (token != '' && token != undefined && token != null) {
      dispatch(fetchLoggedInUser());
      dispatch(getPreferredUniversity());
    }
  }, [token]);

  const allContInfo = [
    {
      iconConInfo: <BsHouseDoor className="icon_cotanct_info" />,
      contactInfo: user?.user_personal_profile?.address ? user?.user_personal_profile?.address : '--------',
    },
    {
      iconConInfo: <MdMailOutline className="icon_cotanct_info" />,
      contactInfo: user?.email,
    },
    {
      iconConInfo: <BsPhone className="icon_cotanct_info" />,
      contactInfo: user?.user_personal_profile?.phone ? user?.user_personal_profile?.phone : '--------',
    },
    {
      iconConInfo: <BsPhone className="icon_cotanct_info" />,
      contactInfo: user?.user_personal_profile?.alternate_phone
        ? user?.user_personal_profile?.alternate_phone
        : '--------',
    },
  ];

  const renderContactInfo = allContInfo.map(perContact => {
    return (
      <div className="contact_icon_title_contianer" key={Math.random()}>
        {perContact.iconConInfo}
        <p className="data_contact_info">{perContact?.contactInfo ? perContact?.contactInfo : '----'}</p>
      </div>
    );
  });

  const renderViewContent = allCountItms.map(perCount => {
    return (
      <div className="post_container" key={Math.random()}>
        <h6 className="number_text"> {perCount.numberCount} </h6>
        <p className="title_number_text"> {perCount.textCount} </p>
      </div>
    );
  });

  const renderPrefferedUniversity = allUnivereName.map(perUniversity => {
    return (
      <li className="univercity_under_container1" key={Math.random()}>
        <p className="ox_foard_text"> {perUniversity.universityName} </p>
      </li>
    );
  });
  // const percentage = 60;

  const percentage = item => {
    if (item.test_id === '1') {
      let scoreCal = (item.overall_score / 340) * 100;
      let scoreData = scoreCal.toFixed(2);
      return scoreData;
    } else {
      let scoreCal = (item.overall_score / 9) * 100;
      let scoreData = scoreCal.toFixed(2);
      return scoreData;
    }
  };
  //  highest Scores validation
  const getMaxScores = scores => {
    const maxScores = {};
    scores.forEach(score => {
      const testId = score.test_id;
      if (!maxScores[testId] || score.overall_score > maxScores[testId].overall_score) {
        maxScores[testId] = score;
      }
    });
    return Object.values(maxScores);
  };

  const maxScores = getMaxScores(allScore);
  const renderScoreExam = maxScores.map(perScore => {
    const testName =
      perScore.test_id === '1'
        ? 'GRE'
        : perScore.test_id === '2'
        ? 'IELTS'
        : perScore.test_id === '3'
        ? 'PTE'
        : perScore.test_id === '4'
        ? 'DET'
        : perScore.test_id === '5'
        ? 'GMAT'
        : perScore.test_id === '6'
        ? 'GRE'
        : perScore.test_id === '7'
        ? 'SAT'
        : perScore.test_id === '8'
        ? 'ACT'
        : null;
    return (
      <div className="progress_per_contianer" key={Math.random()}>
        <CircularProgressbar
          // text={`${percentage(perScore)}%`}
          text={perScore.overall_score ? perScore.overall_score : '---'}
          styles={buildStyles({
            strokeLinecap: 'butt',
            textSize: '22px',
            pathTransitionDuration: 0.5,
            pathColor: `rgba(169, 38, 38,0.9)`,
            textColor: '#A92626',
            trailColor: '#d6d6d6',
          })}
          value={percentage(perScore)}
        />
        <h6 className="exam_text">
          {' '}
          {/* {(perScore?.test_id === "1") ? "GRE" : (perScore?.test_id === "2") ? "IELTS" : (perScore?.test_id === "3") ? "PTE" : (perScore?.test_id === "4") ? "DET" : (perScore?.test_id === "5") ? "GMAT" : (perScore?.test_id === "6") ? "GRE" : (perScore?.test_id === "7") ? "SAT" : (perScore?.test_id === "8") ? "ACT" : null} */}
          {testName}{' '}
        </h6>
      </div>
    );
  });
  return !isLoading ? (
    <div className="one_under_main_container">
      <div className="profile_circle_container">
        <img
          loading="lazy"
          src={user?.user_personal_profile?.profile_pic ? user?.user_personal_profile?.profile_pic : profile_pic}
          alt="photo"
          className="profile_pic_self"
        />
        <div className="active_self" />
      </div>
      <div className="name_username_editbtn_tagline_count_container">
        <h6 className="name_of_profile">
          {user?.username
            ? user?.username
            : user?.user_personal_profile?.first_name
            ? user?.user_personal_profile?.first_name + ' ' + user?.user_personal_profile?.last_name
            : 'Enter Your Username'}
        </h6>
        <p className="username_profile"> {user?.email ? user?.email : 'Enter email'} </p>

        {/* (<div className="two_btn_cotainer">
            <button className="same_btn_profile follow_btn_search follow_btn_activity">
              Follow
            </button>
          </div>) */}

        {props.visible === 'visible' ? null : (
          <div className="two_btn_cotainer">
            <button
              className="msg_btn same_btn_profile"
              //onClick={() => navigate("/my-activity")}
              onClick={handleEditProfile}
            >
              {' '}
              View Profile{' '}
            </button>
            <button className="edit_profile_btn same_btn_profile" onClick={handleEditProfile}>
              Edit Profile
            </button>
          </div>
        )}
        {user?.user_personal_profile?.description != null &&
        user?.user_personal_profile?.description != '' &&
        user?.user_personal_profile?.description != undefined ? (
          // <ParagraphContainer
          //   paraContent={
          //     user?.user_personal_profile?.description
          //       ? user.user_personal_profile.description
          //       : ""
          //   }
          // />
          <LoadMoreText
            text={user?.user_personal_profile?.description ? user.user_personal_profile.description : ''}
            maxLength={50}
          />
        ) : (
          <p className="about_text_description">Update Your Bio Description In Edit Profile Screen...</p>
        )}
      </div>
      <div className="post_follow_container"> {renderViewContent} </div>
      <div className="univercity_contianer">
        <h5 className="heading_university_text"> Preffered University </h5>
        <ul className="univercity_under_contianer">
          {allUnivereName.length > 0 ? renderPrefferedUniversity : 'You have no preference on University'}
        </ul>
      </div>
      <h5 className="score_text"> Exam Score </h5>
      <div className="progressbar_contianer">
        {/* {allScore.length > 0 ? ( */}
        {renderScoreExam}
        {/* // ) : (
                          //   <p className="anothrr_text_description">
                          //     Do Update Your English Proficiency Score and Competitve Exam score
                          //   </p>
                        // )} */}
      </div>
      <h5 className="score_text"> Contact Information </h5> {renderContactInfo}
    </div>
  ) : (
    <LeftProfileSkeleton />
  );
}
