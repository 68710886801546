import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useSelector, useDispatch } from 'react-redux';
import '../CSS file/intropage.css';
import { useNavigate } from 'react-router-dom';
import {
  updateLoggedInUser,
  fetchLoggedInUser,
  userInterestUpdateValue,
} from '../../StateManagement/slicers/userSlicer';
import { fetchPrograms, fetchIntakes, fetchIntakesYear } from '../../StateManagement/slicers/studySlicer';
import { fetchStudyAreas } from '../../StateManagement/slicers/studySlicer';
import { fetchApiBearerToken } from '../../StateManagement/slicers/tokenSlicer';

const robo_icon = require('../../assets/bot.png');

function IntroPage() {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const personalPageData = useSelector(state => state.user.user);
  const { programLevels, intakes, intakeYears } = useSelector(state => state.study);
  const specializations = useSelector(state => state.study.specializations);
  const token = useSelector(state => state.token.token);
  const [userProfile, setUserProfile] = useState({});
  const [programLevelsToRender, setProgramLevelsToRender] = useState([]);
  const [intakesToRender, setIntakesToRender] = useState([]);
  const [intakeYearsToRender, setIntakeYearsToRender] = useState([]);
  const [desiredYearOfAdmission, setDesiredYearOfAdmission] = useState([]);
  const [storedStudyLevel, setStoredStudyLevel] = useState([]);
  const [storeInatke, setStoreInatke] = useState([]);

  useEffect(() => {
    dispatch(fetchApiBearerToken());
  }, [dispatch]);

  useEffect(() => {
    if (token !== '' && token !== undefined && token !== null) {
      dispatch(fetchLoggedInUser());
      dispatch(fetchPrograms());
      dispatch(fetchIntakes());
      dispatch(fetchIntakesYear());
      dispatch(fetchStudyAreas());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (programLevels.length > 0) {
      setProgramLevelsToRender(programLevels);
    }
    if (intakes.length > 0) {
      setIntakesToRender(intakes);
    }
    if (intakeYears.length > 0) {
      setIntakeYearsToRender(intakeYears);
    }
  }, [programLevels, intakes, intakeYears]);

  useEffect(() => {
    if (personalPageData) {
      setUserProfile(personalPageData);
      if (personalPageData?.desired_study_level) {
        if (personalPageData?.desired_study_level.length > 0) {
          let levels = [];
          personalPageData.desired_study_level.forEach(element => {
            levels.push(element.id);
          });
          setStoredStudyLevel(levels);
        }
      }
      if (personalPageData.desired_intake) {
        if (personalPageData?.desired_intake.length > 0) {
          let levels = [];
          personalPageData.desired_intake.forEach(element => {
            levels.push(element.id);
          });
          setStoreInatke(levels);
        }
      }
      if (personalPageData.desired_year_of_admissions) {
        if (personalPageData?.desired_year_of_admissions.length > 0) {
          let levels = [];
          personalPageData.desired_year_of_admissions.forEach(element => {
            levels.push(element.id);
          });
          setDesiredYearOfAdmission(levels);
        }
      }
    }
  }, [personalPageData]);

  const handleSelection = (category, id) => {
    if (category === 'preferred_level_study') {
      if (storedStudyLevel.includes(id)) {
        let newStudyLevel = storedStudyLevel.filter(item => item !== id);
        setStoredStudyLevel(newStudyLevel);
      } else {
        setStoredStudyLevel([...storedStudyLevel, id]);
      }
    }
    if (category === 'year_of_admission') {
      if (desiredYearOfAdmission.includes(id)) {
        let newYearAdmission = desiredYearOfAdmission.filter(item => item !== id);
        setDesiredYearOfAdmission(newYearAdmission);
      } else {
        setDesiredYearOfAdmission([...desiredYearOfAdmission, id]);
      }
    }
    if (category === 'intakes') {
      if (storeInatke.includes(id)) {
        let newstoreInatke = storeInatke.filter(item => item !== id);
        setStoreInatke(newstoreInatke);
      } else {
        setStoreInatke([...storeInatke, id]);
      }
    }
  };

  //create a function select study level and deselect study level if already selected

  const handleSubmit = e => {
    e.preventDefault();
    if ((storedStudyLevel, storeInatke, desiredYearOfAdmission)) {
      dispatch(
        updateLoggedInUser({
          no_of_steps_followed: 3,
        }),
      );
      dispatch(
        userInterestUpdateValue({
          desired_study_level: JSON.stringify(storedStudyLevel),
          desired_intake: JSON.stringify(storeInatke),
          desired_year_of_admission: JSON.stringify(desiredYearOfAdmission),
        }),
        // userInterestUpdateValue({ desired_intake: JSON.stringify(storeInatke) })
      );
      navigate('/StudyPreferencePage');
    } else {
      alert.show('Please Fill all the details! So that we can help You out!', {
        type: 'error',
      });
    }
  };

  return (
    <section className="intro_page_main_container">
      <div className="intro_under_page_container">
        <div className="intro_all_content_container">
          <div className="intro_robo_container">
            <img src={robo_icon} alt="robo_icon" loading="lazy" />
          </div>
          <div className="all_under_content_container_intro">
            {userProfile?.user_personal_profile?.is_fresh_user === '0' ? (
              <>
                <span className="name_text_ps_">
                  Hello,{userProfile?.username || userProfile?.email}, Remember me? <br /> I'm Rainbow, and We’ll do
                  awesome things together ! 🎉
                </span>
                <span className="lightname_text_ps_">
                  But first things first - we've been interrupted last time. Will you find a moment to tell me about
                  yourself?
                </span>
              </>
            ) : (
              <></>
            )}

            <p className="title_text_intro_text">Nice one! </p>

            <p className="extra_text_intro_btn">
              Please help me learn more about your study aspirations I'll be able to then provide recommendations
              personalized for you
            </p>

            <strong className="title_btn_text_intro">Preffered Level of Study</strong>

            <div className="all_btn_container_intro">
              {programLevelsToRender.length > 0 ? (
                programLevelsToRender.map(item => {
                  return (
                    <button
                      type="button"
                      className={storedStudyLevel.includes(item.id) ? 'select_btn_intro_selected' : 'select_btn_intro'}
                      // select deselect the fetch data from the programlevelToRender
                      onClick={() => {
                        handleSelection('preferred_level_study', item.id);
                      }}
                      key={Math.random()}
                    >
                      {item.program_level_name}
                    </button>
                  );
                })
              ) : (
                <></>
              )}
            </div>

            <strong className="title_btn_text_intro">Preffered Year of Admission</strong>
            <div className="all_btn_container_intro">
              {intakeYearsToRender.length > 0 ? (
                intakeYearsToRender.map(perPrefferedBtn => {
                  return (
                    <button
                      type="button"
                      className={
                        desiredYearOfAdmission.includes(perPrefferedBtn.id)
                          ? 'select_btn_intro_selected'
                          : 'select_btn_intro'
                      }
                      onClick={() => {
                        handleSelection('year_of_admission', perPrefferedBtn.id);
                      }}
                      key={Math.random()}
                    >
                      {perPrefferedBtn.year_numeric !== '20' ? perPrefferedBtn.year_numeric : 'later'}
                    </button>
                  );
                })
              ) : (
                <></>
              )}
            </div>

            <strong className="title_btn_text_intro">Preffered Intake</strong>
            <div className="all_btn_container_intro">
              {intakesToRender.length > 0 ? (
                intakesToRender.map(perPreferredIntakeBtn => {
                  return (
                    <button
                      type="button"
                      className={
                        storeInatke.includes(perPreferredIntakeBtn.id)
                          ? 'select_btn_intro_selected'
                          : 'select_btn_intro'
                      }
                      onClick={() => {
                        handleSelection('intakes', perPreferredIntakeBtn.id);
                      }}
                      key={Math.random()}
                    >
                      {perPreferredIntakeBtn.intake_name}
                    </button>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="btn_continue_later_text_container">
            <button type="button" className="sure_btn_self" onClick={handleSubmit}>
              Continue
            </button>
            <button
              type="button"
              className="later_btn_self"
              onClick={() => {
                dispatch(updateLoggedInUser({ is_fresh_user: false }));
                navigate('/application-stepper');
              }}
            >
              Maybe later
            </button>

            <p className="remember_text_all">
              Remember you can always change all information on{' '}
              <span className="highlight_text_blue_intro">your Profile</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default IntroPage;
