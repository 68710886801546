import React, { useState, useEffect, useRef } from 'react';
import '../../CSS file/rightProfile.css';
import university_img from '../../../assets/university-1.png';
import { useLocation } from 'react-router-dom';
import RightSlider from './RightSlider';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserGalleryPhotos } from '../../../StateManagement/slicers/userSlicer';
import { fetchUniversity, getFeaturedUniversity } from '../../../StateManagement/slicers/universitySlicer';
import { useNavigate } from 'react-router-dom';
import { MdPlayCircle } from 'react-icons/md';
import BoxTextSubTextSkeletonLoader from '../../../Components/skeleton loader/BoxTextSubTextSkeletonLoader';
import GallerysectionSkeleton from '../../../Components/skeleton loader/right profile skeleton/GallerysectionSkeleton';

export default function RightProfile(props) {
  const galleryVdoRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { university, isLoading } = useSelector(state => state.university);
  const gallery = useSelector(state => state.user.userGallery);
  const storeUserFriendGallery = useSelector(state => state.user.storeUserFriendGallery);
  let [loading, setLoading] = useState(true);
  const [universityUserInterest, setUniversityUserInterest] = useState([]);
  const [userGallery, setUserGallery] = useState([]);

  useEffect(() => {
    dispatch(fetchUniversity());
    dispatch(getFeaturedUniversity());
    dispatch(fetchUserGalleryPhotos());
  }, []);

  useEffect(() => {
    if (university) {
      if (university?.universities) {
        if (university?.universities.length > 0) {
          setUniversityUserInterest(university?.universities?.slice(0, 5));
        }
      }
    }
  }, [university]);

  useEffect(() => {
    if (location.state === 'id') {
      setUserGallery(storeUserFriendGallery);
    } else {
      setUserGallery(gallery.feeds);
    }
  }, [gallery?.feeds]);

  function handleFullScreen(galleryVdoRef) {
    if (galleryVdoRef.current) {
      galleryVdoRef.current.requestFullscreen();
    }
  }

  function hanldeVideoPlayerButton(e) {
    if (e === 'play') {
      galleryVdoRef.current.play();
    } else {
      galleryVdoRef.current.pause();
    }
  }

  const renderUniversity = universityUserInterest.map(perUniver => {
    return (
      <div className="top_university_text_content_contianer" key={Math.random()}>
        <img
          loading="lazy"
          src={perUniver?.university_logo ? perUniver?.university_logo : university_img}
          alt="university_img"
          className="university_img"
        />

        <button className="uni_location_content">
          <h6 className="uniersity_name" title={perUniver?.u_name}>
            {perUniver?.u_name.substring(0, 30) ? perUniver?.u_name.substring(0, 30) : '-------'}
          </h6>
          <h6 className="location_name">
            {perUniver?.university_locations[0]?.university_in_country[0]?.country_name
              ? perUniver?.university_locations[0]?.university_in_country[0]?.country_name
              : '------'}
          </h6>
        </button>
      </div>
    );
  });

  const renderGalleryImg =
    userGallery?.length > 0 ? (
      userGallery?.slice(0, 9).map(perGallery => {
        if (perGallery) {
          let files = JSON.parse(perGallery.files);
          let detail12 = files[0]
            .split(/[#?]/)[0]
            .split('.')
            .pop()
            .trim();
          if (detail12 === 'mp4' || detail12 === 'mkv' || detail12 === 'avi' || detail12 === 'webm') {
            return (
              <div className="per_photo_contianer" key={Math.random()}>
                <div className="photo_click_contaier" href="#gallerypic">
                  <video className="photo_gallery" ref={galleryVdoRef}>
                    <source src={files[0]} />
                  </video>

                  <div className="play_btn_count_container">
                    <button className="play_btn_container" onClick={() => hanldeVideoPlayerButton('play')}>
                      <MdPlayCircle style={{ fontSize: 35 }} />
                    </button>
                  </div>
                </div>
              </div>
            );
          }

          if (detail12 === 'png' || detail12 === 'jpg' || detail12 === 'jpeg' || detail12 === 'jfif ') {
            return (
              <div className="per_photo_contianer" key={Math.random()}>
                <a className="photo_click_contaier" href="#gallerypic">
                  <img src={files[0]} alt="gallry_photo" className="photo_gallery" loading="lazy" />
                </a>
              </div>
            );
          }
        }
      })
    ) : gallery.total_post === 0 ? (
      <div className="no-photos-message">
        <h6 className="no-photos-message">Gallery not found.</h6>
      </div>
    ) : (
      <GallerysectionSkeleton />
    );
  return (
    <div className="right_under_container">
      <div style={{ paddingTop: 10 }}>
        {props.visible === 'visible' ? null : <RightSlider />}
        {props.visible === 'visible' ? null : (
          <div className="top_university_container">
            <div className="top_university_view_container">
              <h5 className="top_univer_text">Top University</h5>
              <a href="#alluniversity" className="view_univer_text" onClick={() => navigate('/university')}>
                View all
              </a>
            </div>
            {universityUserInterest.length > 0 ? renderUniversity : <BoxTextSubTextSkeletonLoader />}
          </div>
        )}

        <div className="top_university_container">
          <div className="top_university_view_container">
            <h5 className="top_univer_text">Gallery</h5>
            <button
              type="button"
              className="view_univer_text"
              onClick={() => {
                if (location.state === 'id') {
                  navigate('/GalleryMain', { state: 'id' });
                } else {
                  navigate('/GalleryMain');
                }
              }}
              // data-sm-link-text="ok"
            >
              View all
            </button>
          </div>

          <div className="photos_gallery_container">
            {/* {userGallery > 0 ? (
              renderGalleryImg
            ) : (
              <GallerysectionSkeleton />
            )} */}
            {renderGalleryImg}
          </div>
        </div>
      </div>
    </div>
  );
}
