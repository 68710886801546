const config = {
  // BASE_URL: 'https://dwecbackend.developersquad.in/api',
  BASE_URL: 'https://webportal-backend.developersquad.in/api',
};

// const config = {
//   BASE_URL: "https://dwecbackend.dynamicwec.net/api",
// };

export default config;
