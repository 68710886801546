import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeUserSendInteractionWithOthers } from '../../StateManagement/slicers/userSlicer';
import './profilebox.css';
const follow_profile = require('../../assets/profile_pic.jpg');

function ProfileBox(props) {
  let dispatch = useDispatch();
  const { tabToRender, connectionTypeToRender, userConnectionList } = useSelector(state => state.user);

  const [profileBoxContent, setProfileBoxContent] = useState([]);
  const [follow, setFollow] = useState([]);
  const [connection, setConnnection] = useState([]);
  const [connectionList, setConnectionList] = useState([]);

  useEffect(() => {
    if (userConnectionList) {
      setConnectionList(userConnectionList?.data);
    }
  }, [userConnectionList]);
  useEffect(() => {
    if (connectionList) {
      if (connectionTypeToRender === 'Request') {
        setConnnection(connectionList.pending_requests || []);
      } else if (connectionTypeToRender === 'Followers') {
        setConnnection(connectionList.followers || []);
      } else if (connectionTypeToRender === 'Following') {
        setConnnection(connectionList.followings || []);
      }
    }
  }, [tabToRender, connectionTypeToRender, connectionList]);

  useEffect(() => {
    if (props.valueToRender.length > 0) {
      setProfileBoxContent(props.valueToRender);
    }
  }, [props.valueToRender]);

  function handleFollow(e) {
    if (e.value === false || e.value === 0 || !follow.includes(e.index)) {
      dispatch(
        storeUserSendInteractionWithOthers({
          data: {
            if_requested: true,
            if_request_accepted: true,
          },
          id: e.id,
        }),
      );
      setFollow(state => [...state, e.index]);
    }
    if (e.value === true || e.value === 1 || follow.includes(e.index)) {
      let emptyArray = [];
      const indexFound = follow.indexOf(e.index);
      const currentValues = follow;
      currentValues.splice(indexFound, 1);
      currentValues.forEach(item => {
        emptyArray.push(item);
      });
      dispatch(
        storeUserSendInteractionWithOthers({
          data: {
            if_requested: false,
            if_request_accepted: false,
            if_follows: false,
          },
          id: e.id,
        }),
      );
      setFollow(emptyArray);
    }
  }

  const renderAllProfileBox = connection.map((perBox, index) => {
    if (connectionTypeToRender === 'Followers') {
      return (
        <>
          <div className="conn_following_box_contianer" key={Math.random()}>
            <div className="conn_img_title_subtitle_container">
              <img
                className="following_img"
                alt="img"
                loading="lazy"
                src={
                  perBox?.user_profile_performing_action?.profile_pic
                    ? perBox?.user_profile_performing_action?.profile_pic
                    : follow_profile
                }
              />
              <h6 className="title_follow">
                {perBox?.user_profile_performing_action?.first_name &&
                perBox?.user_profile_performing_action?.last_name ? (
                  <>
                    {perBox?.user_profile_performing_action?.first_name.length < 10
                      ? `${perBox?.user_profile_performing_action?.first_name}`
                      : `${perBox?.user_profile_performing_action?.first_name.substring(0, 10)}...`}{' '}
                    {perBox?.user_profile_performing_action?.last_name.length < 10
                      ? `${perBox?.user_profile_performing_action?.last_name}`
                      : `${perBox?.user_profile_performing_action?.last_name.substring(0, 10)}...`}
                  </>
                ) : (
                  '-----'
                )}{' '}
              </h6>
              <p className="sub_title_follow_text">Student</p>
            </div>
            {follow.includes(index) || perBox?.if_user_follow_back === true ? (
              <button
                className="follow_btn1"
                onClick={() =>
                  handleFollow({
                    index: index,
                    value: perBox?.if_user_follow_back,
                    id: perBox?.user_id,
                  })
                }
              >
                {' '}
                Following
              </button>
            ) : (
              <button
                className="follow_btn"
                onClick={() =>
                  handleFollow({
                    index: index,
                    value: perBox?.if_user_follow_back,
                    id: perBox?.user_id,
                  })
                }
              >
                {' '}
                Follow
              </button>
            )}
          </div>
        </>
      );
    }

    if (connectionTypeToRender === 'Following') {
      return (
        <div className="conn_following_box_contianer" key={Math.random()}>
          <div className="conn_img_title_subtitle_container">
            <img
              className="following_img"
              alt="img"
              src={
                perBox?.user_profile_as_recipient?.profile_pic
                  ? perBox.user_profile_as_recipient?.profile_pic
                  : follow_profile
              }
              loading="lazy"
            />
            <h6 className="title_follow">
              {/* Virendrasinh Parmar */}
              {perBox?.user_profile_as_recipient?.first_name && perBox?.user_profile_as_recipient?.last_name ? (
                <>
                  {' '}
                  {perBox?.user_profile_as_recipient?.first_name.length < 10
                    ? `${perBox?.user_profile_as_recipient?.first_name}`
                    : `${perBox?.user_profile_as_recipient?.first_name.substring(0, 10)}...`}{' '}
                  {perBox?.user_profile_as_recipient?.last_name.length < 10
                    ? `${perBox?.user_profile_as_recipient?.last_name}`
                    : `${perBox?.user_profile_as_recipient?.last_name.substring(0, 10)}...`}
                </>
              ) : (
                '-----'
              )}{' '}
              {/* {perBox?.user_profile_as_recipient?.last_name
                ? perBox.user_profile_as_recipient?.last_name
                : "-----"} */}
            </h6>

            <p className="sub_title_follow_text">Student</p>
          </div>
          {/* {!follow.includes(index) ? ( */}
          <button
            className="follow_btn1"
            // onClick={() => handleFollow({
            //   index: index,
            //   value: perBox?.if_requested,
            //   id: perBox?.user_id
            // })}

            onClick={() =>
              dispatch(
                storeUserSendInteractionWithOthers({
                  data: {
                    if_requested: false,
                    if_request_accepted: false,
                    if_follows: false,
                  },
                  id: perBox?.recipient_id,
                }),
              )
            }
          >
            {' '}
            Following
          </button>
          {/* // ) : (
          //   <button className="follow_btn" onClick={() => handleFollow({
          //     index: index,
          //     value: perBox?.if_requested,
          //     id: perBox?.user_id
          //   })}>
          //     {" "}
          //     Follow
          //   </button>
          // )} */}
        </div>
      );
    }

    //   if (connectionTypeToRender === "Request") {
    //     return (
    //       <>
    //         <div className="conn_following_box_contianer" key={Math.random()}>
    //           <div className="conn_img_title_subtitle_container">
    //             <img
    //               className="following_img"
    //               alt="img"
    //               loading="lazy"
    //               src={
    //                 perBox?.user_profile_performing_action?.profile_pic
    //                   ? perBox?.user_profile_performing_action?.profile_pic
    //                   : follow_profile
    //               }
    //             />
    //             <h6 className="title_follow">
    //               {perBox?.user_profile_performing_action?.first_name &&
    //                 perBox?.user_profile_performing_action?.last_name ? (
    //                 <>
    //                   {perBox?.user_profile_performing_action?.first_name.length <
    //                     10
    //                     ? `${perBox?.user_profile_performing_action?.first_name}`
    //                     : `${perBox?.user_profile_performing_action?.first_name.substring(
    //                       0,
    //                       10
    //                     )}...`}{" "}
    //                   {perBox?.user_profile_performing_action?.last_name.length <
    //                     10
    //                     ? `${perBox?.user_profile_performing_action?.last_name}`
    //                     : `${perBox?.user_profile_performing_action?.last_name.substring(
    //                       0,
    //                       10
    //                     )}...`}
    //                 </>
    //               ) : (
    //                 "-----"
    //               )}{" "}
    //             </h6>
    //             <p className="sub_title_follow_text">Student</p>
    //           </div>
    //           {follow.includes(index) || perBox?.if_user_follow_back === true ? (
    //             <button
    //               className="follow_btn1"
    //             // onClick={() => handleFollow(index)}
    //             >
    //               {" "}
    //               Following
    //             </button>
    //           ) : (
    //             <button
    //               className="follow_btn"
    //             // onClick={() => handleFollow(index)}
    //             >
    //               {" "}
    //               Follow
    //             </button>
    //           )}
    //         </div>
    //       </>
    //     );
    //   }
  });
  return (
    <>
      {connection.length > 0 ? (
        <>{renderAllProfileBox}</>
      ) : (
        // <ProfileboxSkeleton />
        'No Connection Found'
      )}
    </>
  );
}

export default ProfileBox;
