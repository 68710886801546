import { async } from '@firebase/util';
import axios from 'axios';
import config from '../Constant/config';

const headers = {
  accept: 'application/json',
  'Accept-Language': 'en-US,en;q=0.8',
  'Access-Control-Allow-Origin': 'http://localhost:3000',
};

const errorHandling = err => {
  if (err?.response?.status === 401) {
    window.location.replace(window?.location?.origin + '/signin');
  } else {
    return err;
  }
};
// //<----------------------------------- Get --------------------------------------------------->

export const fetchToken = () => {
  const accessToken = localStorage.getItem('access_token');
  headers.Authorization = `Bearer ${accessToken}`;
  return accessToken;
};

export async function userLogin(data) {
  let userData = {
    ...data,
    username: data.email,
    grant_type: 'password',
    client_id: '9655b8ba-cec0-4d9e-a325-0a19c8aee3e5',
    client_secret: 'GX1N5B1RvYAlpDw0hXcRSrfbQtrXcg35aFTdkTj2',
  };

  let response = await axios
    .post(`${config.BASE_URL}/login`, userData, {
      headers: headers,
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
  return response;
}

export async function userSignUp(data) {
  let userData = {
    ...data,
    username: data.email,
    grant_type: 'password',
    client_id: '9655b8ba-cec0-4d9e-a325-0a19c8aee3e5',
    client_secret: 'GX1N5B1RvYAlpDw0hXcRSrfbQtrXcg35aFTdkTj2',
  };

  let response = await axios
    .post(`${config.BASE_URL}/register`, userData, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}

export async function userLoginInfo() {
  let response = await axios
    .get(`${config.BASE_URL}/me`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}
export async function fetchUserPost() {
  let response = await axios
    .get(`${config.BASE_URL}/user/myUploads`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}

export async function fetchUserFeedsWall() {
  let response = await axios
    .get(`${config.BASE_URL}/user/myFeedWall`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}

export async function viewUserProfile(id) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/user/viewProfile/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}

export async function fetchUserMyConnection() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/user/myConnections`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}

export async function fetchUserGallery() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/user/myGallery`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}

export async function fetchGenders() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/genders`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}

export async function fetchProgramLevels() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/programLevels`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}

export async function fetchUniversityIntakes() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/study/intakes`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}
export async function fetchUniversityIntakesById(id) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/study/intake/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}

export async function fetchEnglishExams() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/study/englishExams`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}
export async function fetchEnglishExamsById(id) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/study/englishExams/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}

// export async function deleteEnglishExamById(id) {
//   let response = await axios
//     .delete(`${config.BASE_URL}/user/myWorkExperience/${id}`, {
//       headers: headers,
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//
//       return err;
//     });
//
//   return response;
// }

export async function fetchSpecializations() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/study/studyArea`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}
export async function fetchSpecializationsById(id) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/study/studyArea/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}
export async function userSendInteractionWithOthers(data) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .post(`${config.BASE_URL}/user/sendInteraction/${data.id}`, data.data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}
// export async function storeSpecializations(data) {
//   let response = await axios
//     .get(`${config.BASE_URL}/user/updateStoreSocialBackgroundsDetails`, data, {
//       headers: headers,
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       return err;
//     });
//
//   return response;
// }

export async function fetchIntakeYears() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/years`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}
export async function fetchIntakeYearsById(id) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/year/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}

export async function userTestInfo(data) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  headers.contentType = 'multipart/form-data;';
  let response = await axios
    .post(`${config.BASE_URL}/user/testInfo`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}

export async function deleteTestInfo(id) {
  let response = await axios
    .delete(`${config.BASE_URL}/user/testInfo/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function profileUpdate(data) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  headers.contentType = 'multipart/form-data;';
  let response = await axios
    .post(`${config.BASE_URL}/user/profile`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}
export async function updateUserInterest(data) {
  let response = await axios
    .post(`${config.BASE_URL}/user/interestbackground`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}

export async function userAddCommentOnFeed(data) {
  let response = await axios
    .post(`${config.BASE_URL}/user/addCommentToPost/${data.feed_id}`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}

export async function searchUniversalData(data) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/user/searchUniversal`, {
      params: data,
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function searchIntoCategoriesData(data) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/user/searchIntoCategories`, {
      params: data,
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function userFriendFeedPage(id) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/user/friendFeedPage/${id}`, { headers: headers })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}
export async function userFriendGallery(id) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/user/friendGallery/${id}`, { headers: headers })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}
export async function addWorkExperience(data) {
  let response = await axios
    .post(`${config.BASE_URL}/user/workExperience`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function uploadUserPost(data) {
  let response = await axios
    .post(`${config.BASE_URL}/user/uploadPost`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function addEducationalBackground(data) {
  let response = await axios
    .post(`${config.BASE_URL}/user/educationalBackground`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function updateStoreEmergencyContactDetails(data) {
  let response = await axios
    .post(`${config.BASE_URL}/user/updateStoreEmergencyContactDetails`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function updateStoreSocialBackgroundsDetails(data) {
  let response = await axios
    .post(`${config.BASE_URL}/user/updateStoreSocialBackgroundsDetails`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function uploadDocuments(data) {
  let response = await axios
    .post(`${config.BASE_URL}/user/uploadDocuments`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}
export async function deleteUploadDocument(id) {
  let response = await axios
    .delete(`${config.BASE_URL}/user/uploadDocuments/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}
export async function uploadedDocuments() {
  let response = await axios
    .get(`${config.BASE_URL}/user/uploadedDocuments`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });
  return response;
}

// export async function addTestInfo(data) {
//   let response = await axios
//     .post(`${config.BASE_URL}/user/testInfo`, data, {
//       headers: headers,
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       return err;
//     });
//
//   return response;
// }

export async function addRemoveUserPreferenceOnUniversities(data) {
  let response = await axios
    .post(`${config.BASE_URL}/user/addRemoveUserPreferenceOnUniversities`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

//sendInteractionOnPost

export async function sendInteractionOnPost(data, id) {
  let response = await axios
    .post(`${config.BASE_URL}/user/sendInteractionOnPost/${id}`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function fetchUserOnFeedDetail() {
  let response = await axios
    .get(`${config.BASE_URL}/user/fetchFeedDetail`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

// export async function fetch() {
//   let response = await axios
//     .get(`${config.BASE_URL}/user/fetchFeedDetail`, {
//       headers: headers,
//     })
//     .then((response) => {
//
//       return response.data;
//     })
//     .catch((err) => {
//
//       return err;
//     });
//
//   return response;
// }
export async function documentsToBeUploaded() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/user/documentsToBeUploaded`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function updateWorkExperience(data, id) {
  let response = await axios
    .put(`${config.BASE_URL}/user/myWorkExperience/${id}`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}
export async function fetchEducationDetail(id) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/user/myEducationalBackground/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function updateUserEducationDetail(data, id) {
  let response = await axios
    .put(`${config.BASE_URL}/user/myEducationalBackground/${id}`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}
export async function deleteUserEducationDetail(id) {
  let response = await axios
    .delete(`${config.BASE_URL}/user/myEducationalBackground/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function deleteWorkExperience(id) {
  let response = await axios
    .delete(`${config.BASE_URL}/user/myWorkExperience/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function deleteUserPost(id) {
  let response = await axios
    .delete(`${config.BASE_URL}/user/deletePost/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function fetchCountries() {
  let response = await axios
    .get(`${config.BASE_URL}/countries`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}
export async function fetchCountryById(id) {
  let response = await axios
    .get(`${config.BASE_URL}/country/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function fetchDuration() {
  let response = await axios
    .get(`${config.BASE_URL}/study/duration`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function changePassword(data) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;

  let response = await axios
    .put(`${config.BASE_URL}/changePassword`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function fetchEducationInfoById(id) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/user/myWorkExperience/${id}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // return err;
      errorHandling(err);
    });

  return response;
}

export async function universitiesDetails(data) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/universities`, {
      params: data,
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}
export async function universitiesShortListedWithPagination(data) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/university/shortlistedUniversities`, {
      params: data,
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}
export async function courseShortListedWithPagination(data) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/course/shortlistedCourses`, {
      params: data,
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}
export async function universitiesCoursesWithPagination(data) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/university/courses`, {
      params: data,
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}

export async function fetchUniversitiesId(id, name) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/university/${id}/${name}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}

export async function universitiesCoursesDetails() {
  let response = await axios
    .get(`${config.BASE_URL}/university/courses`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}
export async function universitiesCoursesDetailsById(id, name) {
  let response = await axios
    .get(`${config.BASE_URL}/university/course/${id}/${name}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}

export async function fetchTopUniversities() {
  let response = await axios
    .get(`${config.BASE_URL}/university/topUniversities`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

export async function fetchPopularUniversities() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/university/popularUniversities`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

export async function fetchFeaturedUniversities() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/university/featuredUniversities`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}

export async function fetchRecommendedUniversities() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/university/recommendedUniversities`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

export async function fetchPreferredUniversities() {
  let response = await axios
    .get(`${config.BASE_URL}/university/preferredUniversities`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

export async function fetchShortlistedUniversities() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/university/shortlistedUniversities`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

export async function fetchCoursesById(id, name) {
  let response = await axios
    .get(`${config.BASE_URL}/course/${id}/${name}`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}

export async function fetchShortlistedCourses() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/course/shortlistedCourses`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

// courses

export async function fetchTopCourses() {
  let response = await axios
    .get(`${config.BASE_URL}/course/topCourses`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

export async function fetchPopularCourses() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/course/popularCourses`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

export async function fetchFeaturedCourses() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/course/featuredCourses`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}

export async function fetchRecommendedCourses() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/course/recommendedCourses`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

export async function fetchPreferredCourses() {
  let response = await axios
    .get(`${config.BASE_URL}/course/preferredCourses`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

export async function fetchApplyFilterOnCourse(data) {
  //add token
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;

  let response = await axios
    .get(`${config.BASE_URL}/course/fetchApplyFilterOnCourse`, {
      params: data,
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

export async function addRemoveUserPreferenceOnCourses(data) {
  let response = await axios
    .post(`${config.BASE_URL}/user/addRemoveUserPreferenceOnCourses`, data, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

export async function filterUniversityAndCourses(data) {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .post(
      `${config.BASE_URL}/filter`,
      {
        university: data.university,
        location: data.country,
        intakes: data.intakes,
        course: data.course,
        duration: data.duration,
        study_area: data.study_area,
        program_level: data.program_level,
        requirement: data.requirement,
        limit: data.limit,
        offset: data.offset,
        study_area: data.study_area,
        fee_start: data.fee_start,
        fee_end: data.fee_end,
      },
      {
        headers: headers,
      },
    )
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });

  return response;
}

export async function fetchCourses() {
  const token = fetchToken();
  headers.Authorization = `Bearer ${token}`;
  let response = await axios
    .get(`${config.BASE_URL}/courses`, {
      headers: headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}

// forgot password

export async function sendOtpResetPassword(email) {
  try {
    const response = await axios.post(`${config.BASE_URL}/send-otp-reset-password`, { email }, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function verifyOtpResetPassword(email, otp) {
  try {
    const response = await axios.post(`${config.BASE_URL}/otp-verified-reset-password`, { email, otp }, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function resetPassword(email, password) {
  try {
    const response = await axios.post(`${config.BASE_URL}/reset-password`, { email, password }, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Register verify Email
export async function sendOtpRegister(email) {
  let response = await axios
    .post(`${config.BASE_URL}/send-otp-register`, { email }, { headers })
    .then(response => response.data)
    .catch(err => {
      throw err.response.data;
    });
  return response;
}

export async function verifyOtpRegister(email, otp) {
  let response = await axios
    .post(`${config.BASE_URL}/otp-verified-register`, { email, otp }, { headers })
    .then(response => response.data)
    .catch(err => {
      throw err.response.data;
    });
  return response;
}

//
export async function fetchPostDetail(postId) {
  try {
    const response = await axios.get(`${config.BASE_URL}/user/fetchFeedDetail/${postId}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function PubileUserProfile(id) {
  try {
    const response = await axios.get(`${config.BASE_URL}/user/viewProfile/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
