import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SelectBox from '../../Components/SelectBox';
import {
  fetchApplyFilterOnCourse,
  storeFilterData,
  storeFilterListSelector,
} from '../../StateManagement/slicers/filterSlicer';
import Select from 'react-select';

function FilterLeftSide() {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState([]);
  const [tuitionFees, setTuitionFees] = useState([]);
  const { filterList, isLoading, filterData } = useSelector(state => state.filter);
  const countries = useSelector(state => state.country.countries);
  const specializations = useSelector(state => state.study.specializations);
  const programLevel = useSelector(state => state.study.programLevels);
  const intakes = useSelector(state => state.study.intakes);
  const duration = useSelector(state => state.study.duration);
  const englishExamsDetails = useSelector(state => state.study.englishExam);
  const course = useSelector(state => state.course.course);
  const [filterValue, setFilterValue] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      width: '100%',
      backgroundColor: 'rgba(249, 249, 249, 0.82)',
      borderRadius: 3,
      cursor: 'pointer',
      fontFamily: 'IBMMed',
      outline: 'none',
    }),
    control: provided => ({
      ...provided,
      backgroundColor: 'rgba(249, 249, 249, 0.82)',
      border: 0,
      width: '100%',
      fontFamily: 'IBMMed',
      fontSize: 21,
      outline: 'none',
    }),
  };

  useEffect(() => {
    if (filterData) {
      setFilterValue(filterData);

      dispatch(fetchApplyFilterOnCourse(filterData));
    }
  }, [filterData]);

  const allRenderTutData = [
    {
      tutDataName: 'below $ 10,000',
    },
    {
      tutDataName: '$ 10,000 - $ 25,000',
    },
    {
      tutDataName: '$ 25,000 - $ 50,000',
    },
    {
      tutDataName: 'Above $ 50,000',
    },
  ];

  const allRenderLangData = [
    {
      langDataName: 'Not Required',
    },
    {
      langDataName: 'Required',
    },
  ];

  const renderLangText = allRenderLangData.map(perLang => {
    return (
      <div className="squre_text_checkbox_container" key={Math.random()}>
        <input
          type="checkbox"
          className="checkbox_self"
          checked={language.includes(perLang.langDataName) ? true : false}
          id={perLang.langDataName}
          name={perLang.langDataName}
          value={perLang.langDataName}
          onChange={e => {
            if (language.includes(e.target.value)) {
              setLanguage(language.filter(item => item !== e.target.value));
            } else {
              setLanguage([e.target.value]);
            }
          }}
        />
        <label className="checkbox_label" htmlFor={perLang.langDataName}>
          {perLang.langDataName}
        </label>
        <br />
      </div>
    );
  });

  async function handleApplyFilter() {
    let fee_start = 0;
    let fee_end = 0;

    if (tuitionFees.includes('below $ 10,000')) {
      fee_start = 0;
      fee_end = 10000;
    } else if (tuitionFees.includes('$ 10,000 - $ 25,000')) {
      fee_start = 10000;
      fee_end = 25000;
    } else if (tuitionFees.includes('$ 25,000 - $ 50,000')) {
      fee_start = 25000;
      fee_end = 50000;
    } else if (tuitionFees.includes('Above $ 50,000')) {
      fee_start = 50000;
      fee_end = 100000;
    }

    let arr = [];
    selectedOption?.map(item => {
      arr.push(item.value);
    });

    let storeData = {
      university: filterValue.university, //done
      country: filterValue.country, //done
      intakes: filterValue.intake, //done
      course: filterValue.course, //done
      duration: filterValue.duration, // done
      study_area: arr.toString(),
      program_level: filterValue.program_level, //done
      requirement: filterValue.requirement,
      limit: '',
      offset: '',
      study_area: arr.toString(),
      fee_start: fee_start,
      fee_end: fee_end,
    };

    dispatch(storeFilterListSelector(storeData));
    setFilterValue(storeData);
  }
  const renderTutFee = allRenderTutData.map(perTut => {
    return (
      <div className="squre_text_checkbox_container" key={Math.random()}>
        <input
          type="checkbox"
          className="checkbox_self"
          checked={tuitionFees.includes(perTut.tutDataName) ? true : false}
          id={perTut.tutDataName}
          name={perTut.tutDataName}
          value={perTut.tutDataName}
          onChange={e => {
            if (tuitionFees.includes(e.target.value)) {
              setTuitionFees(tuitionFees.filter(item => item !== e.target.value));
            } else {
              setTuitionFees([...tuitionFees, e.target.value]);
            }
          }}
        />
        <label htmlFor={perTut.tutDataName} className="checkbox_label">
          {perTut.tutDataName}
        </label>
        <br />
      </div>
    );
  });

  const handleChange = selectedOptions => {
    setSelectedOption(selectedOptions);
  };

  function handleClearAllFilter() {
    dispatch(
      storeFilterData({
        university: '',
        country: '',
        intakes: '',
        course: '',
        duration: '',
        study_area: '',
        program_level: '',
        requirement: '',
        limit: '',
        offset: '',
        study_area: '',
        fee_start: '',
        fee_end: '',
      }),
    );
  }

  return (
    <div className="all_select_input_box_in_filterleft_side_container">
      <SelectBox
        headTextInput="I would like to study in"
        initialValue={filterValue && filterValue?.country ? filterValue?.country : 'Select option'}
        options={
          countries.length > 0
            ? countries.map(country => {
                return {
                  optionName: country.country_name,
                  optionValue: country.country_name,
                };
              })
            : []
        }
        selectValue={e => {
          setFilterValue({ ...filterValue, country: e });
        }}
      />

      <div>
        <h6 className="select_label_text">Specializing in</h6>
        <div className="input_dialog_style1">
          <Select
            styles={customStyles}
            className="multi_select"
            isMulti
            options={
              specializations.length > 0
                ? specializations.map(special => {
                    return {
                      value: special.id,
                      label: special.name,
                    };
                  })
                : []
            }
            onChange={handleChange}
          />
        </div>
      </div>

      <SelectBox
        headTextInput="Course"
        initialValue={
          filterValue && filterValue?.course !== null
            ? filterValue?.course !== 'Select option'
              ? course?.courses?.filter(item => item.id.toString() === filterValue?.course)[0]?.name
              : 'Select option'
            : 'Select option'
        }
        options={
          course?.courses?.length > 0
            ? course?.courses?.map(course => {
                return {
                  optionName: course?.name,
                  optionValue: course?.id,
                };
              })
            : []
        }
        selectValue={e => {
          setFilterValue({ ...filterValue, course: e });
        }}
      />

      <SelectBox
        headTextInput="With a degree"
        initialValue={
          filterValue && filterValue?.program_level !== null
            ? filterValue?.program_level !== 'Select option'
              ? programLevel?.filter(item => item.id.toString() === filterValue?.program_level)[0]?.program_level_name
              : 'Select option'
            : 'Select option'
        }
        options={
          programLevel?.length > 0
            ? programLevel.map(program => {
                return {
                  optionName: program?.program_level_name,
                  optionValue: program?.id,
                };
              })
            : []
        }
        selectValue={e => {
          setFilterValue({ ...filterValue, program_level: e });
        }}
      />

      <SelectBox
        headTextInput="Select intake"
        initialValue={
          filterValue && filterValue?.intake !== null
            ? filterValue.intake !== 'Select option'
              ? intakes?.filter(item => item.id.toString() === filterValue?.intake)[0]?.intake_name
              : 'Select option'
            : 'Select option'
        }
        options={
          intakes.length > 0
            ? intakes.map(intake => {
                return {
                  optionName: intake?.intake_name,
                  optionValue: intake?.id,
                };
              })
            : []
        }
        selectValue={e => {
          setFilterValue({ ...filterValue, intake: e });
        }}
      />

      <SelectBox
        headTextInput="Select duration"
        initialValue={
          filterValue && filterValue?.duration
            ? filterValue?.duration !== 'Select option'
              ? duration?.filter(item => item?.id?.toString() === filterValue?.duration)[0]?.duration_range
              : 'Select option'
            : 'Select option'
        }
        options={
          duration.length > 0
            ? duration.map(dur => {
                return {
                  optionName: dur.duration_range,
                  optionValue: dur.id,
                };
              })
            : []
        }
        selectValue={e => {
          setFilterValue({ ...filterValue, duration: e });
        }}
      />
      <SelectBox
        headTextInput="Select additional test"
        initialValue={
          filterValue
            ? filterValue?.requirement
              ? filterValue?.requirement?.toUpperCase()
              : 'Select option'
            : 'Select option'
        }
        options={
          englishExamsDetails.length > 0
            ? englishExamsDetails.map(eng => {
                return {
                  optionName: eng.name,
                  optionValue: eng.name,
                };
              })
            : []
        }
        selectValue={e => {
          setFilterValue({ ...filterValue, requirement: e.toLowerCase() });
        }}
      />
      <div>
        <h6 className="select_label_text">Select tution fee</h6>
        <div>{renderTutFee}</div>
      </div>

      <div className="apply_clear_btn_container">
        <button className="more_about_info_btn margin_cut" onClick={handleApplyFilter}>
          Apply now
        </button>
        <button className="more_about_info_btn margin_cut" onClick={handleClearAllFilter}>
          Clear all
        </button>
      </div>
    </div>
  );
}

export default FilterLeftSide;
